import * as React from 'react';
import {PageBackground} from '../../components/PageBackground';
import {PageHeader} from '../../components/PageHeader';
import {VSpacer} from '../../components/VSpacer';
import {Text, TextSize, TextWeight} from '../../components/Text';
import {PageFooter} from '../../components/PageFooter';
import {HAlign, HAligner} from '../../components/HAligner';
import {PictureFrame} from '../../components/PictureFrame';
import {Images} from '../../images/Images';

import * as classnames from 'classnames/bind';
import {CareersLink} from '../../components/CareersLink';

const styles = require('./_PositionComponent.less');
const cx = classnames.bind(styles) as any;

export interface PositionBlock
{
    title: string;
    text?: string;
    list?: string[];
}

const aboutUsBlock: PositionBlock =
{
    title: 'About Us',
    list: [
        'Team of talented, passionate engineers',
        'Ability to grow professionally in a global team of domain experts',
        'Friendly, family-like atmosphere',
        'Snacks and good coffee at the office',
        'Flexible work schedule',
        'Comfortable office with lots of natural light and parking',
        'Great office location',
    ]
};

const overviewBlock: PositionBlock =
{
    title: 'Overview',
    text: `
        
        We're a team of software developers an quality assurance engineers with a big passion for technology. 
        We value open communication and transparency, and we work hard to deliver software for our customers 
        across a diverse range of industries.       
    `
};

export interface PositionPageProps
{
    showOverview?: boolean;
    title:string;
    aboutPositionTitle:string;
    aboutPosition:string;
    aboutRole:string;
    aboutYou?: string[];
    blocks?: PositionBlock[];
}

export class PositionComponent extends React.Component<PositionPageProps, any>
{
    private renderBlock(block: PositionBlock) {
        return (
            <div className={'container'} style={{color: 'black', border: '1px solid transparent'}}>
                <div className={'row'} style={{marginTop: '20px'}}>
                    <div className={'col-sm-12'}>

                        <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                            {block.title}
                        </Text>

                        <VSpacer size={20}/>

                            {block.list &&
                            <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.5}>
                                <ul>
                                    {block.list.map((listItem: string, idx: number) => {
                                        return <li key={idx} dangerouslySetInnerHTML={{__html: `• ` + listItem}}/>;
                                    })}
                                </ul>
                            </Text>
                            }

                            {block.text &&
                            <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                {block.text}
                            </Text>
                            }

                    </div>
                </div>
            </div>
        )
    }

    render()
    {
        const { showOverview = true, aboutYou, blocks } = this.props;

        return  <PageBackground>
                    <PageHeader/>
                    <VSpacer size={15}/>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{}}>
                            <div className={'col-sm-12'}>
                                <HAligner align={HAlign.Center}>
                                    <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                        {this.props.title}
                                    </Text>
                                </HAligner>
                            </div>
                        </div>
                    </div>

                    {showOverview && this.renderBlock(overviewBlock)}

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-12'}>

                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    {this.props.aboutPositionTitle}
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    <div dangerouslySetInnerHTML={{__html:this.props.aboutPosition}}/>
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    The Role
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    <div dangerouslySetInnerHTML={{__html:this.props.aboutRole}}/>
                                </Text>
                            </div>
                        </div>
                    </div>

                    {aboutYou &&
                    <div className={'container'} style={{color: 'black', border: '1px solid transparent'}}>
                        <div className={'row'} style={{marginTop: '20px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    About You
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.5}>
                                    <ul>
                                        {this.props.aboutYou.map((aboutYou: string, idx: number) => {
                                            return <li key={idx} dangerouslySetInnerHTML={{__html: `• ` + aboutYou}}/>;
                                        })}
                                    </ul>
                                </Text>
                            </div>
                        </div>
                    </div>
                    }

                    {blocks && blocks.map(this.renderBlock)}

                    {this.renderBlock(aboutUsBlock)}

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-12'}>
                                <PictureFrame>
                                    <img src={Images.OfficeOuting2v2}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'30px'}}>
                            <div className={'col-sm-12'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Get In Touch
                                </Text>
                                <VSpacer size={25}/>

                                <Text size={TextSize.h3} weight={TextWeight.w300} padding={'0px 0px 20px 0px'} spacing={1} lineHeight={1.3}>
                                    We're always looking for passionate engineers to join our team. Email <CareersLink/> with
                                    your resume and we'll get in touch to discuss next steps.
                                    <br/><br/>
                                </Text>
                            </div>
                            <div className={'col-sm-12'} style={{textAlign:'center'}}>
                                <img className={cx('pictureFrameImg')} src={Images.CompanyAddress}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-12'} style={{marginTop:'5px'}}>
                                <VSpacer size={25}/>
                                <HAligner align={HAlign.Center}>
                                    <Text size={TextSize.h2} spacing={0.9}>
                                        Let's create the future together.
                                    </Text>
                                </HAligner>
                                <VSpacer size={25}/>
                            </div>

                        </div>
                    </div>

                    <PageFooter/>

                </PageBackground>;
    }
}





